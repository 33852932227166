/* PageMenu.css */
.Menu-list {
  font-size: 3.25vw;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotateX(-10deg) rotateY(20deg); /* overwritten by JS */
  padding-top: 5vh;
  padding-left: 1vw;
  position:fixed;
  z-index: 2;
  font-weight: 700;
  text-shadow: #202121;

  
}



.Menu-list-item {
  position: relative;
  color: transparent;
  cursor: pointer;
  /* background-color: rgb(64, 64, 64); */
}

.Menu-list-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 4vh;
  left: -1vw;
  right: -0.1vw;
  height: 0.5vh;
  border-radius: 4vw;
  /* margin-top: -2vh; */
  background: #2cedff;
  transform: scale(0);
  transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  z-index: 3;
}

.Mask {
  display: block;
  position: absolute;
  overflow: hidden;
  color: #b0b0b0;
  top: 0;
  height: 6vw;
  transition: all 1.8s cubic-bezier(0.16, 1.08, 0.38, 1.2);
}

.Mask span {
  display: block;
}

.Mask + .Mask {
  top: -0.05vw;
  height: 5vh;

}

.Menu-list-item:hover,
.Menu-list-item:active {
  .Mask {
    color: #9e7676;
    transform: skewX(25deg) translateX(2px);
  }
  .Mask + .Mask {
    transform: skewX(30deg) translateX(1px);
  }
  &::before {
    transform: scale(1);
  }
}
