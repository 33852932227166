/* App.css */
.App {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px 20px 0;
}

/* Use a grid to organize the projects and contact sections */
.main-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 200px;
  margin-top: 20px;
  padding-top: 20px; /* Add padding between sections */
  padding-left: 200px; /* Add padding to the left */
  z-index: -999;
}

.project-grid {
  display: grid; /* Create a separate grid for projects */
  grid-template-columns: 1fr;
  gap: 200px;
  position: relative; /* Position this grid relative to its container */
  z-index: -333;
}

.contact-grid {
  display: grid; /* Create a separate grid for contact */
  grid-template-columns: 1fr;
  gap: 200px;
  position: relative; /* Position this grid relative to its container */
}

.project {
  padding: 20px;
  background-color: #ac4242;
  border: 2px solid rgb(11, 187, 81);
  z-index: -333;
}

.contact {
  padding: 100px;
}

/* Navbar.css */
.navbar {
  width: 200px;
  background-color: #333;
  color: rgb(221, 143, 143);
  padding: 20px;
}

/* Add fixed size styles for each section */
/* About.css */
#about {
  min-height: 100px; /* Adjust the height as needed */
  color: rgb(124, 123, 124);
  font-weight: bold;
  padding-left: 10px;
}

#projects {
  color: rgb(223, 136, 16);
}

#contact {
  color: rgb(217, 225, 225);
}

.container {
  width: 100%; /* Increase the width as needed */
  height: 100%;
  display: block;
  align-items: left;
  justify-content: left;
  text-shadow: #a2a1a1;
  padding-left: 30px;
}

.reveal-container {
  position: relative;
  overflow: hidden;
  width: fit-content; /* Set the width to fit the content */
  height: 10px; /* Set the height as needed */
  background-color: transparent;
  display: inline-block; /* Display as inline block */
}

.revealed-text {
  position: relative; /* Change to relative position */
  background-color: transparent;
  color: rgba(255, 255, 255, 0.988);
  font-size: 18px;
  display: inline-block; /* Display as inline block */
  overflow: hidden;
  left: 11.8%;
  z-index: 1;
}

/* Navbar.css */
.vertical-text {
  transform: rotate(-90deg);
  transform-origin: left top;
  white-space: nowrap;
  margin-top: 100px;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

a {
  color: white;
  text-decoration: none;
}
